import { Button } from "antd";
import { dateFormat } from "config/commonConfig";
import { IFilteredJurisdictionData } from "modules/organization/models/interface";
import { addToolTip, toTitleCase } from "modules/shared/utility";
import {PreviewQuestionAnswerList} from "../../shared/PreviewQuestionAnswerList";
import { useState } from "react";
import { ProfileOutlined } from "@ant-design/icons";
import PreviewLawList from "../../shared/PreviewLawList";
import { useProjectPreviewStore } from "modules/organization/store";
import { CitationsCovered } from "modules/shared/components/CustomAppIcons";
import { mnDate } from 'modules/shared/services';
interface Props {
  jurisdiction: IFilteredJurisdictionData;
  changeSeries: (jurisdictionId: number, recordId: number) => void;
}

const PreviewProfileCard: React.FC<Props> = ({
  jurisdiction,
  changeSeries,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { getJurisdictionLawIds } = useProjectPreviewStore();

  const lawIds = getJurisdictionLawIds(
    jurisdiction.jurisdiction_id,
    jurisdiction.current_selected_record
  );

  return (
    <div className="card">
      <div className="card-content jurCard">
        <div className="projectHeadingBlk">
          <span className="projectHeading">({toTitleCase(jurisdiction.type)}) {jurisdiction.name}</span>
          {lawIds.length > 0 &&
            <Button
              className="iconOnlyBtn"
              onClick={() => setIsFlipped((pre) => !pre)}
            >
              {isFlipped ? <CitationsCovered /> : <ProfileOutlined />}
            </Button>
          }
        </div>
        <ul className="seriesBarWrapper">
          {jurisdiction.records.map((eachRecord) => (
            <li key={eachRecord.record_id}>
              <Button
                onClick={() => {
                  setIsFlipped(false);
                  changeSeries(
                    jurisdiction.jurisdiction_id,
                    eachRecord.record_id
                  );
                }}
                className="seriesBar"
                style={{
                  backgroundColor: eachRecord.fill_color,
                  borderColor: eachRecord.outline_color,
                  opacity:
                    jurisdiction.current_selected_record ===
                    eachRecord.record_id
                      ? 1
                      : 0.6,
                }}
              />
              <div
                className="seriesInfo"
                style={
                  jurisdiction.current_selected_record === eachRecord.record_id
                    ? {
                        color: eachRecord.fill_color,
                        fontWeight: 700,
                        filter: "brightness(0.5)",
                      }
                    : {
                        color: "var(--mnq-placeholder)",
                        fontWeight: 400,
                      }
                }
              >
                <span>
                  {mnDate(eachRecord.effective_from).format(dateFormat.default)}
                </span>
                <span>
                  {addToolTip(eachRecord.series_title, "top", "default", 20)}
                </span>
                <span>
                  {mnDate(eachRecord.through_to).format(dateFormat.default)}
                </span>
              </div>
            </li>
          ))}
        </ul>
        <div
          className={`previewCardFlipContainer ${isFlipped ? "flipped" : ""}`}
        >
          <div className="frontFace">
            {!isFlipped ? (
              <PreviewQuestionAnswerList
                jurisdiction_id={jurisdiction.jurisdiction_id}
                current_selected_record={jurisdiction.current_selected_record}
              />
            ) : null}
          </div>
          <div className="backFace">
            {isFlipped ? <PreviewLawList lawIds={lawIds} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewProfileCard;
