import ErrorBoundary from "modules/shared/components/ErrorBoundary";
import OrgProjectPreviewView from "./projectPreviewView/OrgProjectPreviewView";
import OrgProjectPreviewFilter from "./projectPreviewFilter/OrgProjectPreviewFilter";
import OrgPojectPreviewOutlet from './OrgPojectPreviewOutlet';

const OrgProjectPreviewPlugin = () => {
  
  return (
    <ErrorBoundary>
      <OrgPojectPreviewOutlet>
        <div className='previewWrapper'>
          <OrgProjectPreviewFilter />
          <OrgProjectPreviewView />
        </div>
      </OrgPojectPreviewOutlet>
    </ErrorBoundary>
  );
};

export default OrgProjectPreviewPlugin;
