import { useState } from "react";
import {
  ICitation,
  IFlattenQuestion,
  IQuestionAnswer,
  ICitationWithText
} from 'modules/organization/models/interface';
import { useProjectPreviewStore } from 'modules/organization/store';
import React from 'react';
import { TagsOutlined, WarningOutlined, ProfileOutlined } from '@ant-design/icons';
import { dateFormat } from 'config/commonConfig';
import { Button, Popover, Spin } from 'antd';
import PreviewLawList from './PreviewLawList';
import { mnDate } from 'modules/shared/services';
import { ProjectPublishService } from 'modules/organization/services';

interface PreviewQuestionProps {
  question: IFlattenQuestion;
  answer: IQuestionAnswer;
  show_citations?: boolean;
  show_caution_note?: boolean;
}

const PreviewQuestion: React.FC<PreviewQuestionProps> = ({
  answer,
  question,
  show_citations = true,
  show_caution_note = true
}) => {

  return (
    <li>
      <label>
        Q{question.layer_string} {question.title}
      </label>
      <div className='previewAnswerBlk'>
        {show_citations && answer.citations && (
          <PreviewQuestionAttachedCitations citations={answer.citations ?? []} />
        )}
        {show_caution_note && answer.caution_note && (
          <PreviewQuestionAttachedCautionNote cautionNote={answer.caution_note} />
        )}
        <div>{getPreviewAnswer(question, answer).join(', ')}</div>
      </div>
    </li>
  );
};


const PreviewQuestionAttachedCitations: React.FC<{citations: ICitation[]}> = ({citations}) =>{
  const [citationList, setCitationList] = useState<ICitationWithText[] | null>(null);
  const [dataLoader, setDataLoader] = useState<boolean>(false);

  const citationListBlk = () => {
    return (
      <div className='spinWrap'>
        <Spin spinning={dataLoader}>
          <ul className='popContent'>
              {citationList?.map(citation => <li key={citation.citation_id}>
                  <div className='citationHeader'>
                    <Popover content={<PreviewLawList lawIds={[citation.law_id]} />} title="Law text" placement='left' trigger="click">
                      <Button className="iconOnlyBtn smallIcon"><ProfileOutlined /></Button>
                    </Popover>
                    <label>{citation.law_title}</label>
                  </div>
                  <p className='citationContent'>{citation.is_full_text ? "Full text citation." : citation.law_text}</p>
              </li>)}
          </ul>
        </Spin>
      </div>
    )
  }

  const getCitationsData = async () => {
    setDataLoader(true);
    const citationIds = citations.map(eachCitation => eachCitation.citation_id);
    try {
      const { data } = await new ProjectPublishService().getCitationData(citationIds);
      setCitationList(data.data.citationList);
      setDataLoader(false);
    } catch (error) {
      setDataLoader(false);
    }
  };

  const onOpenChange = (visible: boolean) => {
    if (visible && !citationList) {
      getCitationsData();
    }
  }

  return (
    <Popover onOpenChange={onOpenChange} content={citations && citationListBlk()} title="Excerpts from the law" placement='left' trigger="click">
      <Button className='iconOnlyBtn smallIcon'><TagsOutlined /></Button>
    </Popover>
  )
}

const PreviewQuestionAttachedCautionNote: React.FC<{cautionNote: string}> = ({cautionNote}) =>{
  return (
    <Popover content={<p className='popContent'>{cautionNote}</p>} title="Caution note" placement='left' trigger="click">
      <Button className='iconOnlyBtn smallIcon'><WarningOutlined /></Button>
    </Popover>
  )
}

const getPreviewAnswer = (  question: IFlattenQuestion, answer: IQuestionAnswer ) => {
  const answers:string[] = [];
  if (question.data.question_type_id === 7) {
    answers.push(mnDate(answer.open_response).format(dateFormat.default));
  }
  else if ([1, 2].includes(question.data.question_type_id) && answer.open_response) {
    answers.push(answer.open_response);
  }
  else if (question.data.question_type_id === 3) {
    answers.push(`$${answer.open_response}`);
  }
  else{
    answer.response_id
    .forEach(
      (eachId) =>{
        const findOption = question.data.options.find(
          ({ response_id }) => response_id === eachId
        );
        if(findOption && findOption.response_value){
          answers.push(findOption.response_value)
        }
      }
    );
  }
  return answers;
};


interface Props {
  jurisdiction_id: number;
  current_selected_record: number;
  show_citations?: boolean;
  show_caution_note?: boolean;
}

const PreviewQuestionAnswerList: React.FC<Props> = ({
  jurisdiction_id,
  current_selected_record,
  show_citations,
  show_caution_note
}) => {
  const { getCurrentAnswers, flatQuestionTree, filterControls } = useProjectPreviewStore();

  const answers = getCurrentAnswers(jurisdiction_id, current_selected_record);

  const _answeredQuestion: number[] = getCurrentAnswers(
    jurisdiction_id,
    current_selected_record
  ).map((eachAns) => eachAns.question_id);

  const isQuestionSelected = (questionId: number) => {
    return filterControls.filter.type === 'explore' ?
     !filterControls.filter.exploreQuestionData || filterControls.filter.exploreQuestionData.question_id === questionId
     : !filterControls.filter.filterQuestionData || filterControls.filter.filterQuestionData.some(({question_id}) => questionId === question_id)
  }

  return (
    <ul className="previewQuestionList">
      {flatQuestionTree.map(
        (eachQuestion) =>
          _answeredQuestion.includes(eachQuestion.key) && isQuestionSelected(eachQuestion.key) && (
            <PreviewQuestion
              key={
                answers.find(
                  ({ question_id }) => eachQuestion.key === question_id
                )?.answer_id
              }
              question={eachQuestion}
              answer={
                answers.find(
                  ({ question_id }) => eachQuestion.key === question_id
                ) as IQuestionAnswer
              }
              show_citations={show_citations}
              show_caution_note={show_caution_note}
            />
          )
      )}
    </ul>
  );
};

export {
  PreviewQuestionAnswerList,
  PreviewQuestionAttachedCitations,
  PreviewQuestionAttachedCautionNote,
  getPreviewAnswer
};
