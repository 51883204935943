import { useProjectPreviewStore } from 'modules/organization/store';
import { ReactNode, useEffect } from 'react';

interface Props {
    children: ReactNode
}

const OrgPojectPreviewOutlet: React.FC<Props> = ({ children }) => {
  const { resetProjectPreviewState } = useProjectPreviewStore();
  
  useEffect(() => {
    return () => {
      resetProjectPreviewState()
    }
  }, [resetProjectPreviewState])

  return (
    <div>
      {children}
    </div>
  );
}

export default OrgPojectPreviewOutlet;
