import { Result } from "antd";

const EmbeddingNotAllowed = () => {
  return (
    <div className="displayFlex flexJCenter flexAlignCenter iframe-disallowed">
      <Result
        className="displayFlex flexJCenter flexAlignCenter flexVertical"
        status="error"
        title="Embedding this iframe is not permitted"
        subTitle={
          <p>
            Please contact{" "}
            <b>
              <a href="mailto:elizabeth.platt@temple.edu">Elizabeth A Platt</a>{" "}
            </b>
            for the resolution.
            <br />
            Any of the below mentioned points could be the reason.
          </p>
        }
      >
        <div>
          1. Your site is not listed in the <b>authorized sites</b>.
        </div>
        <div>
          2. Your iframe code is not <b>up to date</b>.
        </div>
      </Result>
    </div>
  );
};

export default EmbeddingNotAllowed;
