import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
dayjs.extend(minMax);
dayjs.extend(utc);

export type DefaultDateType = string | number | Date | Dayjs | null | undefined;

export type DateType = Dayjs;

export type DateManupulateType = ManipulateType | undefined;

export type DateOpUnitType = OpUnitType;

/**
 * Date service
 * @class Date service
 */
class DateService {
  /**
   * Date  of date service
   */
  public date: Dayjs;

  constructor(dateString?: DefaultDateType, utc: boolean = true) {
    if(utc) {
      this.date = dayjs.utc(dateString,undefined,true);
    }
    else{
      this.date = dayjs(dateString);
    }
  }

  /**
   * Formats date service
   * @param formatString
   * @returns format
   */
  format(formatString?: string | undefined): string {
    return this.date.format(formatString);
  }

  /**
   * Years date service
   * @returns year
   */
  year(): number {
    return this.date.year();
  }

  /**
   * Adds date service
   * @param value
   * @param [unit]
   * @returns add
   */
  add(value: number, unit?: DateManupulateType): DateType {
    this.date = this.date.add(value, unit);
    return this.date;
  }

  /**
   * Determines whether valid is
   * @returns true if valid
   */
  isValid(): boolean {
    return this.date.isValid();
  }

  /**
   * Starts of
   * @param unit
   * @returns start of
   */
  startOf(unit: DateOpUnitType): DateType {
    this.date = this.date.startOf(unit);
    return this.date;
  }

  /**
   * Ends of
   * @param unit
   * @returns end of
   */
  endOf(unit: DateOpUnitType): DateType {
    this.date = this.date.endOf(unit);
    return this.date;
  }

  /**
   * Maxs date service
   * @param dates 
   * @returns max 
   */
  max(...dates: DateType[]): DateType | null {
    const max = dayjs.max(dates);
    if (max) {
      this.date = max;
      return max
    } else {
      return null
    }
  }

  /**
   * Mins date service
   * @param dates 
   * @returns min 
   */
  min(...dates: DateType[]): DateType | null {
    const min = dayjs.min(dates);
    if (min) {
      this.date = min;
      return min
    } else {
      return null
    }
  }

  /**
   * Determines whether after is
   * @param [date] 
   * @param [unit] 
   * @returns true if after 
   */
  isAfter(date?: DefaultDateType, unit?: DateOpUnitType | undefined): boolean {
    return this.date.isAfter(date, unit);
  }

  /**
   * Determines whether before is
   * @param [date] 
   * @param [unit] 
   * @returns true if before 
   */
  isBefore(date?: DefaultDateType, unit?: DateOpUnitType | undefined): boolean {
    return this.date.isBefore(date, unit);
  }

  /**
   * Diffs date service
   * @param [date] 
   * @returns diff 
   */
  diff(date?: DefaultDateType): number {
    return this.date.diff(date);
  }
}

/**
 * date
 * @
 * @note this is only the date service class instance
 * @param [defaultDate] 
 * @returns date service class instance
 */
export function mnDate(defaultDate?: DefaultDateType, utc: boolean = true) {
  return new DateService(defaultDate,utc);
}
