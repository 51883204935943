import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { appConfig } from '../../../config/config';
import { LoggerService } from "./logger.service";
import { IHttpError } from "models/interface";
import { TokenService } from "./token.service";

interface ICustomError extends IHttpError {
  refreshAction: Function | string;
  params?: string;
}

export class ApiService {
  private static instance?: ApiService;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new ApiService();
    }
    return this.instance;
  }

  async privateClient() {
    const _tokenService = TokenService.getInstance();

    let client = axios.create({
      baseURL: process.env.REACT_APP_API_URL as string,
      headers: {
        'X-Iframe-Token': _tokenService.getToken(),
      },
      // headers: {
      //   'X-Iframe-Token': `U2FsdGVkX19Vgdb63U5ywD9QJ8VkWxygDSmxWDjORT8FSp84FOiHhvEgFtMTOeDXsQADCr/T8vvP0mjpFnAhK80rha2Qr2mPUPX5exzs6EVIt+nAMXw4+EqqCXvEWcFSVBl90QgtouFN6infVP3QaY87dlLWh5wzAoeMSxa896uFJ5GethmkYm0CZFSVXAcJPqkExhRtk0ZXrOyxFUI6+L0e/HbP1POp+5zjQlwbtPIjBFxdU7NmXypmPmeQEfNGJYEOUcxyFsD2iqa7HXiB/HeoAkILnAFVOyUuErTSeQZ9NQnRvxtP/bhvT+73eCjDnUuaDOZzu9a+L7+AEY+ALA==`,
      // },
    });

    client.interceptors.request.use(
      (config) => this.onRequest(config),
      (error) => {
        return Promise.reject(error);
      }
    );

    client.interceptors.response.use(
      (response) => this.onResponse(response),
      (error) => this.onResponseError(error)
    );

    return client;
  }

  async publicClient() {
    const requestConfig: {baseURL: string, headers?: any} = {
      baseURL: process.env.REACT_APP_API_URL as string,
    }
    let client = axios.create(requestConfig);

    client.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    client.interceptors.response.use(
      (response) => this.onResponse(response),
      (error) => this.onResponseError(error)
    );

    return client;
  }

  private onRequest(request: InternalAxiosRequestConfig) {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    if (request.method === 'get') {
      if (!request.params) {
        request = {
          ...request,
          params: {
            timezone: currentTimeZone ?? appConfig.REACT_APP_DEFAULT_TIMEZONE,
          },
        };
      } else {
        request.params = {
          ...request.params,
          timezone: currentTimeZone ?? appConfig.REACT_APP_DEFAULT_TIMEZONE,
        };
      }
    } else {
      const bodyData = request.data??{}
      request.data = {
        ...bodyData,
        timezone: currentTimeZone ?? appConfig.REACT_APP_DEFAULT_TIMEZONE,
      }
    }

    return request;
  }

  private onResponse(response: AxiosResponse) {
    return response;
  }

  private onResponseError(error: AxiosError): Promise<ICustomError> {
    this.logTheError(error);

    if (this.isAPIDown(error)) {
      return Promise.reject({
        code: 503,
        message: "Service Unavailable",
        error: error,
      });
    } else if (error.response?.status === 403) {
      return Promise.reject({
        code: 403,
        message: "Access Denied",
        error: error,
      });
    } else if (error.response?.status === 401) {
      return Promise.reject({
        code: 401,
        message: "Unauthorized",
        error: error,
      });
    }
    return Promise.reject({
      code: error.response?.status,
      message: error.message,
      error: error,
    });
  }

  private logTheError(error: AxiosError) {
    let errorCode = error.response?.status;
    let errorResponse:any = error?.response?.data;
    let errorMessage = error.message;

    if (this.isAPIDown(error)) {
      errorCode = 503;
      errorMessage = "Service Unavailable";
    }

    if (error.request?.config?.url !== 'exception/log') {
      if(navigator.onLine){
        new LoggerService().log({
          message: errorMessage,
          status_code: errorCode,
          payload: error.request,
          errors: error
        })
        .catch((error) => console.log(error))
        .finally(() => {
          if(errorCode === 401 && errorResponse?.message && 
            (errorResponse?.message.toLowerCase() === 'dose not matched with user' || errorResponse?.message.toLowerCase() === 'does not matched with user' || errorResponse?.message.toLowerCase() === 'dose not matched with user and organization' || errorResponse?.message.toLowerCase() === 'does not matched with user and organization')
            ){
            window.location.replace(`${window.location.origin}/idle-user`);
          }
        });
      }
    }

  }

  private isAPIDown(err: AxiosError) {
    return !!err.isAxiosError && !err.response;
  }
  
}