import { useProjectPreviewStore } from "modules/organization/store";
import { GlobalOutlined } from "@ant-design/icons";
import { Select } from "antd";
import {
  IFlattenQuestion,
  IOption,
} from "modules/organization/models/interface";
import { useState } from "react";

const PreviewExploreQuestions = () => {
  const { filterControls, flatQuestionTree, updateFilterControls } =
    useProjectPreviewStore();

  const [selectedValue, setSelectedValue] = useState<number>();
  const [selectedQuestion, setSelectedQuestion] = useState<IFlattenQuestion>();

  const handleOnExplore = (eachQuestion: IFlattenQuestion) => {
    let _selectedValue = selectedValue;
    if (
      !selectedQuestion ||
      selectedQuestion.data.question_id !== eachQuestion.data.question_id
    ) {
      _selectedValue = undefined;
      setSelectedQuestion(eachQuestion);
    }
    updateFilterControls({
      ...filterControls,
      filter: {
        type: "explore",
        exploreQuestionData: {
          question_id: eachQuestion.data.question_id,
          selected_option:
            eachQuestion.data.question_type_id !== 4
              ? null
              : _selectedValue ?? eachQuestion.data.options[0].response_id,
        },
        filterQuestionData: null,
      },
    });
    setSelectedValue(_selectedValue);
  };
  return (
    <div className="explore-question-list">
      {flatQuestionTree.map((eachQuestion: IFlattenQuestion) => {
        return (
          <div
            key={eachQuestion.key}
            className={`explore-question-card ${
              eachQuestion.key ===
              filterControls.filter.exploreQuestionData?.question_id
                ? "explore-question-card-active"
                : ""
            }`}
          >
            <div
              className="explore-question-card-top"
              onClick={() => handleOnExplore(eachQuestion)}
            >
              <div className="explore-question-string">
                {" "}
                Q{eachQuestion.layer_string} {eachQuestion.title}
              </div>
              <div>
                <GlobalOutlined /> Explore
              </div>
            </div>
            {eachQuestion.data.question_type_id === 4 &&
              eachQuestion.key ===
                filterControls.filter.exploreQuestionData?.question_id && (
                <Select
                  aria-label={"Select Response"}
                  defaultValue={eachQuestion.data.options[0].response_id}
                  onSelect={(value) => {
                    setSelectedValue(() => value);
                    updateFilterControls({
                      ...filterControls,
                      filter: {
                        type: "explore",
                        exploreQuestionData: {
                          question_id: eachQuestion.data.question_id,
                          selected_option: value,
                        },
                        filterQuestionData: null,
                      },
                    });
                  }}
                >
                  {eachQuestion.data.options.map((option: IOption) => (
                    <Select.Option
                      key={option.response_id}
                      value={option.response_id}
                      label={option.response_value}
                    >
                      {option.response_value}
                    </Select.Option>
                  ))}
                </Select>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default PreviewExploreQuestions;
