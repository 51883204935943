import { Fragment } from "react";
import { useProjectPreviewStore } from "modules/organization/store";
import { Button, Checkbox, Form, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  IFlattenQuestion,
  IOption,
} from "modules/organization/models/interface";
const PreviewFilterQuestions = () => {
  const { filterControls, flatQuestionTree, updateFilterControls } =
    useProjectPreviewStore();
  const [form] = Form.useForm();

  const handleOnFilter = (
    isChecked: boolean,
    eachOption: IOption,
    eachQuestion: IFlattenQuestion
  ) => {
    let updatedFilterControls = { ...filterControls };
    updatedFilterControls.filter.type = "filter";
    updatedFilterControls.filter.exploreQuestionData = null;
    if (updatedFilterControls.filter.filterQuestionData) {
      const existingDataIndex =
        updatedFilterControls.filter.filterQuestionData.findIndex(
          (data) => data.question_id === eachQuestion.data.question_id
        );
      // if question_id exists
      if (existingDataIndex !== -1) {
        if (eachQuestion.data.question_type_id === 4) {
          if (isChecked) {
            updatedFilterControls.filter.filterQuestionData[
              existingDataIndex
            ].selected_options.push(eachOption.response_id);
            updatedFilterControls.filter.filterQuestionData[
              existingDataIndex
            ].not_answered = false;
          } else {
            updatedFilterControls.filter.filterQuestionData[
              existingDataIndex
            ].selected_options =
              updatedFilterControls.filter.filterQuestionData[
                existingDataIndex
              ].selected_options.filter(
                (item) => item !== eachOption.response_id
              );
            if (
              updatedFilterControls.filter.filterQuestionData[existingDataIndex]
                .selected_options.length === 0
            ) {
              updatedFilterControls.filter.filterQuestionData.splice(
                existingDataIndex,
                1
              );
            }
          }
        } else {
          updatedFilterControls.filter.filterQuestionData[
            existingDataIndex
          ].selected_options = [eachOption.response_id];
          updatedFilterControls.filter.filterQuestionData[
            existingDataIndex
          ].not_answered = false;
        }
      } else {
        updatedFilterControls.filter.filterQuestionData.push({
          question_id: eachQuestion.data.question_id,
          selected_options: [eachOption.response_id],
          not_answered: false,
        });
      }
    } else {
      updatedFilterControls.filter.filterQuestionData = [
        {
          question_id: eachQuestion.data.question_id,
          selected_options: [eachOption.response_id],
          not_answered: false,
        },
      ];
    }
    if (updatedFilterControls.filter.filterQuestionData.length === 0) {
      updatedFilterControls.filter.filterQuestionData = null;
    }
    updateFilterControls(updatedFilterControls);
  };
  const handleOnFilterRemove = (
    eachQuestion: IFlattenQuestion,
    eachOption: IOption | null,
    isNotAnswered = false
  ) => {
    const updatedFilterControls = { ...filterControls };
    if (updatedFilterControls.filter.filterQuestionData !== null) {
      updatedFilterControls.filter.filterQuestionData =
        updatedFilterControls.filter.filterQuestionData
          .map((item) => {
            if (item.question_id === eachQuestion.data.question_id) {
              if (!isNotAnswered) {
                const newSelectedOptions = item.selected_options.filter(
                  (value) => value !== eachOption?.response_id
                );
                return { ...item, selected_options: newSelectedOptions };
              } else {
                return { ...item, not_answered: false };
              }
            } else {
              return item;
            }
          })
          .filter(
            (item) => item.selected_options.length > 0 || item.not_answered
          );
      if (updatedFilterControls.filter.filterQuestionData.length === 0) {
        updatedFilterControls.filter.filterQuestionData = null;
      }
      updateFilterControls(updatedFilterControls);
    }
  };

  const handleOnNotAnswered = (
    isChecked: boolean,
    eachQuestion: IFlattenQuestion
  ) => {
    const updatedFilterControls = { ...filterControls };
    updatedFilterControls.filter.type = "filter";
    if (updatedFilterControls.filter.filterQuestionData !== null) {
      updatedFilterControls.filter.filterQuestionData =
        updatedFilterControls.filter.filterQuestionData
          .map((item) => {
            if (
              item.question_id === eachQuestion.data.question_id &&
              isChecked
            ) {
              item.selected_options = [];
              item.not_answered = true;
            } else if (
              item.question_id === eachQuestion.data.question_id &&
              !isChecked
            ) {
              item.selected_options = [];
              item.not_answered = false;
            }
            return item;
          })
          .filter(
            (item) => item.selected_options.length > 0 || item.not_answered
          );
      if (
        updatedFilterControls.filter.filterQuestionData.length > 0 &&
        updatedFilterControls.filter.filterQuestionData.every((item) => {
          return (
            item.question_id !== eachQuestion.data.question_id && isChecked
          );
        })
      ) {
        updatedFilterControls.filter.filterQuestionData.push({
          question_id: eachQuestion.data.question_id,
          selected_options: [],
          not_answered: true,
        });
      }
    } else {
      updatedFilterControls.filter.filterQuestionData = [
        {
          question_id: eachQuestion.data.question_id,
          selected_options: [],
          not_answered: true,
        },
      ];
    }
    if (updatedFilterControls.filter.filterQuestionData.length === 0) {
      updatedFilterControls.filter.filterQuestionData = null;
    }
    updateFilterControls(updatedFilterControls);
  };

  const getFilterControlOfQuestion = (questionId: number) => {
    const _filterControlsFilterQuestionData = [
      ...(filterControls.filter.filterQuestionData ?? []),
    ];
    return _filterControlsFilterQuestionData.find(
      (each) => each.question_id === questionId
    );
  };

  return (
    <Fragment>
      {filterControls?.filter?.filterQuestionData &&
        filterControls?.filter?.filterQuestionData?.length > 0 && (
          <div className="preview-criteria-card">
            <label>Criteria:</label>
            <ul className="filterQstList">
              {flatQuestionTree.map((eachQuestion: IFlattenQuestion) => {
                if (
                  filterControls.filter.filterQuestionData?.some(
                    (filteredQuestion) =>
                      filteredQuestion.question_id ===
                      eachQuestion.data.question_id
                  )
                ) {
                  return (
                    <li key={eachQuestion.key}>
                      <div>
                        <strong>Q{eachQuestion.layer_string}</strong>{" "}
                        {eachQuestion.title}
                      </div>
                      <div className="optionSelected">
                        {eachQuestion.data.options.map(
                          (eachOption: IOption) => {
                            if (
                              filterControls.filter.filterQuestionData?.some(
                                (filteredQuestion) =>
                                  filteredQuestion.question_id ===
                                    eachQuestion.data.question_id &&
                                  filteredQuestion.selected_options.includes(
                                    eachOption.response_id
                                  )
                              )
                            ) {
                              return (
                                <div
                                  key={eachOption.response_id}
                                  className="optionItem"
                                >
                                  <div>{eachOption.response_value}</div>
                                  <Button
                                    icon={<CloseOutlined />}
                                    className="actionIconBtn"
                                    onClick={() =>
                                      handleOnFilterRemove(
                                        eachQuestion,
                                        eachOption
                                      )
                                    }
                                  ></Button>
                                </div>
                              );
                            }
                            return null;
                          }
                        )}
                      </div>
                      <div className="optionSelected">
                        {filterControls.filter.filterQuestionData.some(
                          (filteredQuestion) =>
                            filteredQuestion.question_id ===
                              eachQuestion.data.question_id &&
                            filteredQuestion.not_answered
                        ) && (
                          <div className="optionItem">
                            {" "}
                            <div>Not Answered</div>
                            <Button
                              icon={<CloseOutlined />}
                              className="actionIconBtn"
                              onClick={() =>
                                handleOnFilterRemove(eachQuestion, null, true)
                              }
                            ></Button>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        )}
      <div className="explore-question-list">
        {flatQuestionTree.map((eachQuestion) => {
          const currrentSelectedQuestion = getFilterControlOfQuestion(
            eachQuestion.data.question_id
          );
          return (
            <div key={eachQuestion.key} className={`explore-question-card `}>
              <div className="explore-question-card-top">
                <div className="explore-question-string">
                  {" "}
                  Q{eachQuestion.layer_string} {eachQuestion.title}
                </div>
              </div>
              <Form form={form} layout="vertical">
                {eachQuestion.data.question_type_id === 4 && (
                  <Form.Item>
                    <Checkbox.Group
                      value={currrentSelectedQuestion?.selected_options ?? []}
                    >
                      <div className="checkboxWrap">
                        {eachQuestion.data.options.map((eachOption) => (
                          <Checkbox
                            key={eachOption.response_id}
                            value={eachOption.response_id}
                            onChange={(event) =>
                              handleOnFilter(
                                event.target.checked,
                                eachOption,
                                eachQuestion
                              )
                            }
                          >
                            {eachOption.response_value}
                          </Checkbox>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </Form.Item>
                )}

                {eachQuestion.data.question_type_id !== 4 && (
                  <Form.Item>
                    <Radio.Group
                      value={currrentSelectedQuestion?.selected_options[0]}
                    >
                      <div className="checkboxWrap">
                        {eachQuestion.data.options.map((eachOption) => (
                          <Radio
                            key={eachOption.response_id}
                            value={eachOption.response_id}
                            onChange={(event) =>
                              handleOnFilter(
                                event.target.checked,
                                eachOption,
                                eachQuestion
                              )
                            }
                          >
                            {eachOption.response_value}
                          </Radio>
                        ))}
                      </div>
                    </Radio.Group>
                  </Form.Item>
                )}
                <hr />
                <Form.Item>
                  <Checkbox
                    onChange={(event) =>
                      handleOnNotAnswered(event.target.checked, eachQuestion)
                    }
                    checked={currrentSelectedQuestion?.not_answered}
                  >
                    Not Answerd
                  </Checkbox>
                </Form.Item>
              </Form>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default PreviewFilterQuestions;
