interface ICommonRecordParams {
    project_slug?: string;
    record_id?: number | null;
    law_id?: number | null;
  }
  
  export class URLService<T extends ICommonRecordParams> {
    private data: T;
    private projectSlug?: string;
  
    constructor(data: T, projectSlug?: string) {
      this.data = data;
      this.projectSlug = projectSlug || data.project_slug
    }
  
    private replaceKeys(template: string): string {
      if (!this.projectSlug || (template.includes('{record_id}') && !this.data.record_id) ) {
          throw new Error("project_slug or record_id is undefined.");
      }
      let updatedUrl = template.replace('{project_slug}', this.projectSlug);
      if (this.data.record_id && template.includes('{record_id}')) {
        updatedUrl = updatedUrl.replace('{record_id}', this.data.record_id.toString());
      }
      if (this.data.law_id && template.includes('{law_id}')) {
        updatedUrl = updatedUrl.replace('{law_id}', this.data.law_id.toString());
      }
      return updatedUrl;
    }
  
    public formatURL(url: string): { updatedURL: string; updatedData: T } {
      const updatedURL = this.replaceKeys(url);
      const updatedData = { ...this.data };
      if (this.data.record_id) {
        delete updatedData.record_id;
      }
      return { updatedURL, updatedData };
    }
  }
  