import OrgProjectIframePage from "modules/organization/pages/IframePage";
import NotFound from "modules/shared/components/NotFound";
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route
} from "react-router-dom";
const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="project/:project_slug" element={<OrgProjectIframePage />} />
            <Route path="*" element={<NotFound />} />
        </>
    )
)

export default function AppRoutes() {
    return <RouterProvider router={router} />
}
