import { IApiBaseResponse } from "models/interface";
import { RoutesEnum } from "../../../models/enums";
import { ApiService } from "../../shared/services";
import { URLService } from "./url.service";
import { IResponseAllowedSitesData } from "../models/interface";

export class AllowedSitesService {

    public async getAllowedSites(project_slug: string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_GET_ALLOWED_SITES)
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseAllowedSitesData>>(updatedURL)
    }

}

