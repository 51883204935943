import { FC, useEffect, useRef } from 'react';

const useOutsideClickHandler = (ref:any, callback:any) => {
    const savedCallback = useRef(callback);
  
    useEffect(() => {
      const handleClick = (event:any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          savedCallback.current();
        }
      };
  
      // Bind the event listener to the document
      document.addEventListener('click', handleClick);
  
      // Clean up the event listener on unmount
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [ref]);
  };
  
  const OutsideClickWrapper:FC<any> = ({ children, onOutsideClick }) => {
    const wrapperRef = useRef(null);
  
    useOutsideClickHandler(wrapperRef, onOutsideClick);
  
    return <div ref={wrapperRef}>{children}</div>;
  };
  
  export default OutsideClickWrapper;