export const colorConfigs = {
  DEFAULT_TAG_COLOR: "#5c7784",
};

export const dateFormat = {
  default: "MM/DD/YYYY",
  defaultTimeStamp: "MM/DD/YYYY, hh:mm A",
  database: "YYYY-MM-DD",
  tableDateTime: "MMM DD, YYYY, hh:mm A",
  tableDate: "MMM DD, YYYY",
  fileTimestamp: "YYYY-MM-DD-HH-mm-ss",
};
