import { useProjectPreviewStore } from 'modules/organization/store';
import React, { useCallback } from 'react';
import Masonry from 'react-masonry-css';
import PreviewProfileCard from './components/PreviewProfileCard';
import PreviewProfileFilterCard from './components/PreviewProfileFilterCard';

const PreviewProfileView: React.FC<{}> = () => {
  const { filteredData, setFilteredData, getFilteredDataForProfileView } = useProjectPreviewStore();

  const filteredProfileViewData = useCallback(
    () => {
      return getFilteredDataForProfileView();
    },
    [getFilteredDataForProfileView],
  );

  const changeSeries = (jurisdictionId: number, recordId: number) => {
    const tmpFilteredData = filteredData ? filteredData.map((eachJurisdiction) => {
        if (eachJurisdiction.jurisdiction_id === jurisdictionId) {
            return {
                ...eachJurisdiction,
                current_selected_record: recordId
            }
        }
        return eachJurisdiction;
    }) : [];
    setFilteredData(tmpFilteredData);
  }

  return (
    <div
      className="tabContent"
      id="tabpanel-profile"
      role="tabpanel"
      aria-labelledby="tab-profile"
    >
      <Masonry
        breakpointCols={2}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <PreviewProfileFilterCard />
        {filteredProfileViewData().map((item) => (<PreviewProfileCard key={item.jurisdiction_id} jurisdiction={{...item}} changeSeries={changeSeries} />))}
      </Masonry>
    </div>
  );
};

export default PreviewProfileView;
