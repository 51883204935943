import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import PreviewExploreQuestions from "./PreviewExploreQuestions";
import PreviewFilterQuestions from "./PreviewFilterQuestions";
import { useProjectPreviewStore } from "modules/organization/store";
import { dateFormat } from 'config/commonConfig';
import { mnDate } from 'modules/shared/services';

const PreviewFilterContainer:FC<{showFilterForm: boolean}> = ({showFilterForm}) => {
  const [currentMode, setCurrentMode] = useState("explore");
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const { filterControls, validDates, updateFilterControls } =
    useProjectPreviewStore();
  const handleOnReset = () => {
    updateFilterControls({
      selected_date: validDates?.end
        ? mnDate(validDates.end).format(dateFormat.database)
        : null,
      filter: {
        type: "explore",
        exploreQuestionData: null,
        filterQuestionData: null,
      },
    });
  };
  useEffect(() => {
    if (
      filterControls.filter.exploreQuestionData !== null ||
      filterControls.filter.filterQuestionData !== null
    ) {
      setIsResetDisabled(false);
    } else {
        setIsResetDisabled(true);
    }
  }, [filterControls]);
  return (
    <div className={showFilterForm ? "previewFilterWrap active": "previewFilterWrap"}>
      <div className="tabContainer">
        <div className="previewTabMenu">
          <div className="tabMenu" role="tablist" aria-labelledby="tab-header">
            <Button
              id="tab-explore"
              role="tab"
              aria-selected={currentMode === "explore"}
              aria-controls="tabpanel-explore"
              className={currentMode === "explore" ? "active" : ""}
              onClick={() => {
                setCurrentMode("explore");
                handleOnReset();
              }}
            >
              Explore
            </Button>
            <Button
              id="tab-filter"
              role="tab"
              aria-selected={currentMode === "filter"}
              aria-controls="tabpanel-filter"
              className={currentMode === "filter" ? "active" : ""}
              onClick={() => {
                setCurrentMode("filter");
                handleOnReset();
              }}
            >
              Filter
            </Button>
          </div>
          <Button
            role="reset"
            onClick={() => {
              setCurrentMode("explore");
              handleOnReset();
            }}
            disabled={isResetDisabled}
          >
            Reset
          </Button>
        </div>
        <div className="tabContent">
          {currentMode === "explore" && <PreviewExploreQuestions />}
          {currentMode === "filter" && <PreviewFilterQuestions />}
        </div>
      </div>
    </div>
  );
};

export default PreviewFilterContainer;
