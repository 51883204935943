import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

export const toTitleCase = (value: string) => {
  return value.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const addToolTip = (
  data: any,
  placement?: TooltipPlacement | undefined,
  cursorType: string = 'default',
  maxCharacterCount: number = 15,
  dataCount: boolean = false,
  overlayInnerStyle: Object = { textTransform: 'none' },
  overlayStyle: Object = { textTransform: 'none' }
) => {
  if (dataCount) {
    if (data) {
      if (data.length > 1) {
        return (
          <Tooltip
            overlayStyle={overlayStyle}
            overlayInnerStyle={overlayInnerStyle}
            title={`${data.map((each: any) => each)}`}
            arrowPointAtCenter={true}
            trigger={['hover','focus']}
          >
            <span style={{ cursor: cursorType }}>{`${data[0]} + ${
              data.length - 1
            } more...`}</span>
          </Tooltip>
        );
      } else {
        return data[0];
      }
    }
  } else {
    if (data.length > maxCharacterCount) {
      return (
        <Tooltip
          overlayStyle={overlayStyle}
          overlayInnerStyle={overlayInnerStyle}
          placement={placement ? placement : 'rightTop'}
          title={data}
          trigger={['hover','focus']}
        >
          <span style={{ cursor: cursorType }}>
            {data.slice(0, maxCharacterCount).trim()}...
          </span>
        </Tooltip>
      );
    }
    return <span style={{ cursor: cursorType }}>{data}</span>;
  }
};

export const getEncodedData = (data: {}) => {
  return btoa(JSON.stringify(data));
};

export const getDecodedData = (data: string) => {
  return JSON.parse(atob(data));
};

export const getQueryParams = (url: string) => {
  const data = url.split('?')[1];
  const value = data.split('&');
  const paramsObject: { [key: string]: any } = {};

  for (let param of value) {
    const [key, value] = param.split('=');
    paramsObject[key] = getDecodedData(value);
  }

  return paramsObject;
};

export function getDomain(url: string, subdomain: boolean = false): string {
  // Remove protocol and 'www.' from the URL
  let modifiedUrl = url.replace(/(https?:\/\/)?/i, '');

  // Remove any path after the domain
  modifiedUrl = modifiedUrl.split('/')[0];

  // Split the modified URL by '.' to get the domain parts
  const domainParts = modifiedUrl.split('.');
  
  if (!subdomain) {
      // If subdomain is not required, take the last two parts of the domain
      if(domainParts.length > 2) {
        modifiedUrl = domainParts.slice(1).join('.');
      } else {
        modifiedUrl = domainParts.join('.');
      }
      
  }

  return modifiedUrl;
}