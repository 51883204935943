import { Spin } from 'antd';
import { ILaw } from 'modules/organization/models/interface';
import { ProjectPublishService } from 'modules/organization/services';
import React, { useEffect, useState } from 'react';
import { dateFormat } from 'config/commonConfig';
import { mnDate } from 'modules/shared/services';

interface Props {
  lawIds: number[];
}

const PreviewLawList: React.FC<Props> = ({ lawIds }) => {
  
  const [lawList, setLawlist] = useState<ILaw[]>([]);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const getLawData = async () => {
    setDataLoader(true);
    try {
      const { data } = await new ProjectPublishService().getLawData(lawIds);
      setLawlist(data.data.lawList);
      setDataLoader(false);
    } catch (error) {
      setDataLoader(false);
    }
  };

  useEffect(() => {
    getLawData().catch(console.error);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='spinWrap'>
      <Spin spinning={dataLoader}>
        <ul className='popContent'>
          {lawList.map((eachLaw) => (
            <li key={eachLaw.law_id}>
              <div className='headingTxt'>{eachLaw.title}</div>
              <div className='statusIndicator'>
                {mnDate(eachLaw.effective_from).format(dateFormat.default)} -{' '}
                {mnDate(eachLaw.through_to).format(dateFormat.default)}
              </div>
              <div className='backFaceCont' dangerouslySetInnerHTML={{ __html: eachLaw.html_description}}></div>
            </li>
          ))}
        </ul>
      </Spin>
    </div>
  );
};

export default PreviewLawList;
