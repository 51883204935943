import { Button, Select, Tooltip } from 'antd';
import { useProjectPreviewStore } from 'modules/organization/store';
import React from 'react';
import {
  SortAscendingOutlined,
  SortDescendingOutlined
} from '@ant-design/icons';
import { IProfileViewFilter } from 'modules/organization/models/interface';
import PreviewLegend from '../../shared/PreviewLegend';

const PreviewProfileFilterCard: React.FC<{}> = () => {
  const {
    filteredData,
    profileViewFilter,
    setProfileViewFilter
  } = useProjectPreviewStore();

  const onSelectDeselect = (value: number) => {
    const tmpFilteredJurisdiction =
      profileViewFilter.selected_jurisdiction.includes(value)
        ? profileViewFilter.selected_jurisdiction.filter(
            (eachItem) => eachItem !== value
          )
        : [...profileViewFilter.selected_jurisdiction, value];
    setProfileViewFilter({
      ...profileViewFilter,
      selected_jurisdiction: tmpFilteredJurisdiction
    });
  };

  const sortHandler = (key: IProfileViewFilter['sort_key']) => {
    const { sort_key, sort_order } = profileViewFilter;
    setProfileViewFilter({
      ...profileViewFilter,
      sort_key: key,
      sort_order: sort_key === key && sort_order === 'asc' ? 'desc' : 'asc'
    });
  };

  return (
    <div className="card filter1">
      <div className="card-content">
        <div className="profileViewFilterBlk">
          <div className="profileViewSortActions">
            <Button
              onClick={() => sortHandler('jurisdiction')}
              className={
                profileViewFilter.sort_key === 'jurisdiction'
                  ? 'iconOnlyBtn'
                  : 'iconOnlyBtn inactive'
              }
            >
              <Tooltip title="Sort alphabetically" trigger={['hover','focus']}>
                {profileViewFilter.sort_order === 'asc' ||
                profileViewFilter.sort_key !== 'jurisdiction' ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )}
              </Tooltip>
            </Button>
          </div>
        </div>
        <Select
          options={
            filteredData?.map((eachData) => {
              return {
                label: eachData.name,
                value: eachData.jurisdiction_id
              };
            }) ?? []
          }
          optionFilterProp="label"
          value={profileViewFilter.selected_jurisdiction ?? []}
          onSelect={onSelectDeselect}
          onDeselect={onSelectDeselect}
          mode="multiple"
          placeholder="Select jurisdiction"
          disabled={!filteredData || filteredData?.length < 1}
        />
        <PreviewLegend />
      </div>
    </div>
  );
};

export default PreviewProfileFilterCard;
