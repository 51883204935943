import { Button } from 'antd';
import { Fragment, useState } from 'react';
import PreviewMapView from './mapView/PreviewMapView';
import PreviewProfileView from './profileView/PreviewProfileView';
import PreviewTableView from './tableView/PreviewTableView';

const OrgProjectPreviewView = () => {
  const [currentMode, setCurrentMode] = useState<'profile' | 'map' | 'table'>("profile");
  return (
    <div className="tabContainer previewRightContainer">
      <div className="tabMenu" role="tablist" aria-labelledby="tab-header">
        <Button
          id="tab-profile"
          role="tab"
          aria-selected={currentMode === "profile"}
          aria-controls="tabpanel-profile"
          className={currentMode === "profile" ? "active" : ""}
          onClick={() => {
            setCurrentMode("profile");
          }}
        >
          Profile
        </Button>
        <Button
          id="tab-map"
          role="tab"
          aria-selected={currentMode === "map"}
          aria-controls="tabpanel-map"
          className={currentMode === "map" ? "active" : ""}
          onClick={() => {
            setCurrentMode("map");
          }}
        >
          Map
        </Button>
        <Button
          id="tab-table"
          role="tab"
          aria-selected={currentMode === "table"}
          aria-controls="tabpanel-table"
          className={currentMode === "table" ? "active" : ""}
          onClick={() => {
            setCurrentMode("table");
          }}
        >
          Table
        </Button>
      </div>
      <Fragment>
        {currentMode === "map" && <PreviewMapView setCurrentMode={setCurrentMode}/>}
        {currentMode === "profile" && <PreviewProfileView />}
        {currentMode === "table" && <PreviewTableView setCurrentMode={setCurrentMode}/>}
      </Fragment>
    </div>
  );
};

export default OrgProjectPreviewView;
