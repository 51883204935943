import { Spin } from 'antd';
import {
  useProjectPreviewStore
} from 'modules/organization/store';
import CustomError from 'modules/shared/components/CustomError';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const OrgProjectPreviewWrapper: React.FC<Props> = ({ children }) => {
  const { dataLoader, dataFetchError } = useProjectPreviewStore();

  return (
    <Spin spinning={dataLoader}>
      {dataFetchError ? (
        <CustomError errorDetails={dataFetchError} />
      ) : (
        <>{children ?? null}</>
      )}
    </Spin>
  );
};

export default OrgProjectPreviewWrapper;
