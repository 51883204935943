import { useCallback, useEffect, useState } from "react";
import * as d3 from "d3";
import { useProjectPreviewStore } from "modules/organization/store";
import { sliderBottom } from "../utils/d3Slider";
import { IFilteredJurisdictionData, IJurisdictionData } from "modules/organization/models/interface";
import { dateFormat } from 'config/commonConfig';
import { mnDate } from 'modules/shared/services';

const HistoricalDistributionChart = () => {

    const { filterControls, validDates, validAllDates, jurisdictionData, questionList, totalRecordCount, getColorScale, updateFilterControls, setFilteredData } = useProjectPreviewStore();
    const [chartData, setChartData] = useState<{date: Date, [key:string]: any}[]>([]);
    const [maxMatchedRecordCount, setMaxMatchedRecordCount] = useState(1);

    const isDateBetween = (startDate:Date, endDate:Date, dateToCheck:Date) => {
        return startDate <= dateToCheck && dateToCheck <= endDate;
    }

    const generateChartData = useCallback((dates: Date[])=> {
        const _jurisdictionData:IJurisdictionData[] = JSON.parse(JSON.stringify(jurisdictionData ? [...jurisdictionData] : []));
        //** For All */
        if(!filterControls.filter.exploreQuestionData && !filterControls.filter.filterQuestionData){
            let maxMatchedRecordCount = 1;
            const data = dates.map(eachDate => {
                
                let matchedRecordCount = 0;
                _jurisdictionData?.forEach(eachJ => {
                    const isRecordFound = eachJ?.records?.filter((eachRec) => {
                        return isDateBetween(new Date(eachRec.effective_from), new Date(eachRec.through_to), eachDate)
                    }) ?? [];
                    matchedRecordCount = matchedRecordCount + isRecordFound.length;
                    if(matchedRecordCount > maxMatchedRecordCount){
                        maxMatchedRecordCount = matchedRecordCount
                    }
                });
                return {
                    date: eachDate,
                    all: matchedRecordCount
                }
            });

            setMaxMatchedRecordCount(maxMatchedRecordCount);
            setChartData(data);
        }
        else if(filterControls.filter.type === 'explore' && filterControls.filter.exploreQuestionData){
            // For Explore Filter
            const selectedQuestionId = filterControls.filter.exploreQuestionData.question_id;
            const selectedQuestion = questionList?.find(each => each.question_id === selectedQuestionId);

            const data = dates.map(eachDate => {
                const eachDateData:any = {
                    date: eachDate,
                }

                let questionOptions = selectedQuestion?.options ?? [];
                
                if(filterControls.filter?.exploreQuestionData?.selected_option){
                    questionOptions = questionOptions.filter(each => each.response_id === filterControls.filter.exploreQuestionData?.selected_option)
                }

                questionOptions.forEach(eachOption => {
                    let matchedRecordCount = 0;
                    _jurisdictionData?.forEach(eachJ => {
                        const isRecordFound = eachJ?.records?.filter((eachRec) => {
                            return isDateBetween(new Date(eachRec.effective_from), new Date(eachRec.through_to), eachDate)
                            &&  eachRec.question_answers.find(eachQuestionAns => eachQuestionAns.question_id === selectedQuestion?.question_id && eachQuestionAns.response_id.includes(eachOption.response_id))
                        }) ?? [];
        
                        matchedRecordCount = matchedRecordCount + isRecordFound.length;
                    });
                    eachDateData[eachOption.response_id.toString()] = matchedRecordCount;
                });
                
                return eachDateData;
            });
            setChartData(data);
        }
        else if(filterControls.filter.type === 'filter' && filterControls.filter.filterQuestionData){
            // For Filter
            const filterQuestionData = filterControls.filter.filterQuestionData;
            const data = dates.map(eachDate => {
                let matchedRecordCount = 0;
                _jurisdictionData?.forEach(eachJ => {
                    const isRecordFound = eachJ?.records?.filter((eachRec) => {
                        const filterQuestionCheck: {q_id: number; all_ans_found: boolean}[] = filterQuestionData.map(each => {
                            return {
                                q_id: each.question_id,
                                all_ans_found: false
                            }
                        });

                        filterQuestionData.forEach(eachFq => {
                            if(eachFq.not_answered){
                                const hasNotAnswered = eachRec.question_answers.find( each =>
                                    each.question_id === eachFq.question_id
                                );
                                if(!hasNotAnswered){
                                    const findCheckData = filterQuestionCheck.find(each => each.q_id === eachFq.question_id);
                                    if(findCheckData){
                                        findCheckData.all_ans_found = true;
                                    }
                                }
                            }
                            else{
                                const isQuestionAnsFound = eachRec.question_answers.find( each =>
                                    each.question_id === eachFq.question_id
                                    && eachFq.selected_options.every(eachQAO => each.response_id.includes(eachQAO))
                                );
    
                                if(isQuestionAnsFound){
                                    const findCheckData = filterQuestionCheck.find(each => each.q_id === eachFq.question_id);
                                    if(findCheckData){
                                        findCheckData.all_ans_found = true;
                                    }
                                }
                            }
                            
                        });
                        return (isDateBetween(new Date(eachRec.effective_from), new Date(eachRec.through_to), eachDate) && filterQuestionCheck.filter(each => each.all_ans_found).length === filterQuestionData.length)

                    }) ?? [];
    
                    matchedRecordCount = matchedRecordCount + isRecordFound.length;
                });

                return {
                    date: eachDate,
                    all: matchedRecordCount
                }
            });

            setChartData(data);
        }
        
    },[filterControls, jurisdictionData, questionList])

    useEffect(()=>{
        if(validAllDates.length){
            generateChartData(validAllDates);
        }
    }, [validAllDates, generateChartData]);


    //** For Filter Jurisdiction start*/
    const doFilterJurisdiction = useCallback(()=> {
        let _filteredData: IJurisdictionData[] = [];
        const getColorScaleData = getColorScale();
        if(filterControls.selected_date){
            const _jurisdictionData: IJurisdictionData[] =  JSON.parse(JSON.stringify([...jurisdictionData ?? []]));
            if(!filterControls.filter.exploreQuestionData && !filterControls.filter.filterQuestionData){
                _filteredData = _jurisdictionData.filter(eachJ => {
                    const recordsFound = eachJ?.records?.filter((eachRec) => {
                        return isDateBetween(new Date(eachRec.effective_from), new Date(eachRec.through_to), new Date(filterControls.selected_date??''))
                    });
    
                    if(recordsFound?.length){
                        eachJ.records = recordsFound.map(each => {
                            return {
                                ...each,
                                fill_color: getColorScaleData[0].fillColor,
                                outline_color: getColorScaleData[0].fillColor,
                            }
                        });
                        return true
                    }
                    return false
                });
            }
            else if(filterControls.filter.type === 'explore' && filterControls.filter.exploreQuestionData){
                // For Explore Filter
                const selectedQuestionId = filterControls.filter.exploreQuestionData.question_id;
                const selectedQuestion = questionList?.find(each => each.question_id === selectedQuestionId);
                let questionOptions = selectedQuestion?.options ?? [];

                if (filterControls.filter.exploreQuestionData?.selected_option) {
                    questionOptions = questionOptions.filter(each => each.response_id === filterControls.filter.exploreQuestionData?.selected_option)
                }
                

                _filteredData = _jurisdictionData.filter(eachJ => {
                    const records = eachJ?.records ?? [];
                    let recordFoundOnThatDate = records.filter((eachRec) => {
                        return isDateBetween(new Date(eachRec.effective_from), new Date(eachRec.through_to), new Date(filterControls.selected_date??''))
                        && eachRec.question_answers.find(each => each.question_id === selectedQuestionId)
                    });

                    if (filterControls.filter.exploreQuestionData?.selected_option) {
                        recordFoundOnThatDate = recordFoundOnThatDate.filter(eachRec => {
                            return eachRec.question_answers.find(each => each.question_id === selectedQuestionId)?.response_id.includes(filterControls.filter.exploreQuestionData?.selected_option ?? 0)
                        })
                    }

                    questionOptions.forEach(eachOption => {
                        recordFoundOnThatDate.forEach(eachRF => {
                            const findQuestionAns = eachRF.question_answers.find(each => each.question_id === selectedQuestionId && each.response_id.includes(eachOption.response_id));
                            if(findQuestionAns){
                                const getColor = getColorScaleData.find(each => each.key === eachOption.response_id.toString());
                                if(!eachRF.fill_color && getColor){
                                    eachRF.fill_color = getColor.fillColor
                                }
                            }
                        });
                    })
    
                    if(recordFoundOnThatDate.length){
                        eachJ.records = recordFoundOnThatDate;
                        return true;
                    }
                    
                    return false;
                })
            }
            else if(filterControls.filter.type === 'filter' && filterControls.filter.filterQuestionData){
                // For Filter
                const filterQuestionData = filterControls.filter.filterQuestionData;
                _filteredData = _jurisdictionData.filter(eachJ => {
                    const records = eachJ.records ?? [];
                    const recordsFound = records.filter((eachRec) => {
                        const filterQuestionCheck: {q_id: number; all_ans_found: boolean}[] = filterQuestionData.map(each => {
                            return {
                                q_id: each.question_id,
                                all_ans_found: false
                            }
                        });

                        filterQuestionData.forEach(eachFq => {

                            if(eachFq.not_answered){
                                const hasNotAnswered = eachRec.question_answers.find( each =>
                                    each.question_id === eachFq.question_id
                                );
                                if(!hasNotAnswered){
                                    const findCheckData = filterQuestionCheck.find(each => each.q_id === eachFq.question_id);
                                    if(findCheckData){
                                        findCheckData.all_ans_found = true;
                                    }
                                }
                            }
                            else{
                                const isQuestionAnsFound = eachRec.question_answers.find( each =>
                                    each.question_id === eachFq.question_id
                                    && eachFq.selected_options.every(eachQAO => each.response_id.includes(eachQAO))
                                );
    
                                if(isQuestionAnsFound){
                                    const findCheckData = filterQuestionCheck.find(each => each.q_id === eachFq.question_id);
                                    if(findCheckData){
                                        findCheckData.all_ans_found = true;
                                    }
                                }
                            }
                        });
                        return (isDateBetween(new Date(eachRec.effective_from), new Date(eachRec.through_to), new Date(filterControls.selected_date??'')) && filterQuestionCheck.filter(each => each.all_ans_found).length === filterQuestionData.length)

                    });

                    if(recordsFound.length){
                        eachJ.records = recordsFound.map(each => {
                            return {
                                ...each,
                                fill_color: getColorScaleData[0].fillColor,
                                outline_color: getColorScaleData[0].fillColor,
                            }
                        });
                        return true;
                    }
                    return false
                });
            }


        }

        const _filteredDataTransform: IFilteredJurisdictionData[] = _filteredData.map(each => {

            return {
                jurisdiction_id: each.jurisdiction_id,
                type: each.type,
                jurisdiction_type_id: each.jurisdiction_type_id,
                name: each.name,
                lat: each.lat,
                lon: each.lon,
                country_code: each.country_code,
                country: each.country,
                state_code: each.state_code,
                custom_points: each.custom_points,
                current_selected_record: each?.records?.length ? each?.records[0].record_id : 0,
                records: each?.records?.map(each => {
                    return {
                        record_id: each.record_id,
                        effective_from: each.effective_from,
                        through_to: each.through_to,
                        series_title: each.series_title,
                        outline_color: each.outline_color,
                        fill_color: each.fill_color
                    }
                }) ?? []
            }
        })

        setFilteredData(_filteredDataTransform);
        
    },[filterControls, jurisdictionData, questionList, setFilteredData, getColorScale]);
    

    useEffect(()=>{
        doFilterJurisdiction()
    }, [filterControls.selected_date, filterControls.filter, doFilterJurisdiction])

    //** For Filter Jurisdiction end*/

    //** For Render Chart Start */
    const renderChart = useCallback(()=> {
        if(validDates && jurisdictionData){

            const historicalDistributionChart = document.getElementById('historicalDistributionChart');
            if(historicalDistributionChart){
                historicalDistributionChart.innerHTML = ''
            }

            const getColorScaleData = getColorScale();
        
            const chart = d3.select("#historicalDistributionChart");
            const margin = {top: 20, right: 30, bottom: 20, left: 25};
            const width = parseInt(chart.attr("width")) - margin.left - margin.right;
            const height = parseInt(chart.attr("height")) - margin.top - margin.bottom;
                    
            const xScale = d3.scaleTime().range([0, width]);
            const yScale = d3.scaleLinear().range([height, 0]);
            const zColorScale = d3.scaleOrdinal(d3.schemeCategory10);

            const stack = d3.stack();
            const area:any = d3.area()
            .x(function(d: any, i) { 
                return xScale(d.data.date); 
            })
            .y0(function(d) { return yScale(d[0]); })
            .y1(function(d) { return yScale(d[1]); });


            const chartGroup = chart
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            const keys = Object.keys(chartData[0]).filter(each => each !== 'date');

            xScale.domain([new Date(validDates?.start), new Date(validDates?.end)]);
            yScale.domain([0, maxMatchedRecordCount ])
            zColorScale.domain(keys);
            stack.keys(keys);   

            const layer = chartGroup.selectAll(".layer")
            .data(stack(chartData))
            .enter().append("g")
            .attr("class", "layer");
        
            layer.append("path")
                .attr("class", "area")
                .style("fill", function(d) {
                    const getColor = getColorScaleData.find(each => each.key === d.key);
                    return getColor?.fillColor ?? '#A41E35';
                })
                .attr("d", area);
                
            chartGroup.append("g")
                .attr("class", "axis-x")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(xScale).ticks(7));
          
            chartGroup.append("g")
                .attr("class", "axis-y")
                .call(d3.axisLeft(yScale).ticks(3));
            
            const sliderSimple = sliderBottom()
                .min(new Date(validDates?.start))
                .max(new Date(validDates?.end))
                .default(new Date(filterControls?.selected_date ?? ''))
                .width(width)
                .step(1000*60*60*24)
                .displayFormat(d3.timeFormat("%m/%d/%Y"))
                .fill('#A41E35')
                .handle('M -5.5 -5.5 v 10 l 6 5.5 v 10 C 0.5063 76.9927 0.5127 64.9853 0.519 19.978 v -10 l 6 -5.5 v -10 z')
                .on('onchange', (val:string) => {})
                .on('end', (val: string) => {
                    updateFilterControls({
                        ...filterControls,
                        selected_date: mnDate(val).format(dateFormat.database)
                    })
                });

            chartGroup.call(sliderSimple);

            chartGroup.select(".slider")
                .attr('role', 'slider')
                .attr('value',filterControls?.selected_date ?? '');
            chartGroup.select(".slider .parameter-value text")
                .attr('dy', '-4.5em');
            chartGroup.select(".slider .parameter-value path")
                .attr('focusable', 'false')
                .attr('tabindex', '-1');

            chartGroup.select(".slider .track")
            .attr('stroke-width', '2');
            chartGroup.select(".slider .track-inset")
            .attr('stroke-width', '2');
            chartGroup.select(".slider .track-fill")
            .attr('stroke-width', '2');

            chartGroup.selectAll('.axis-y .tick text')
            .attr('x', '-12');
            
        }

    },[chartData, jurisdictionData, validDates, filterControls, maxMatchedRecordCount, getColorScale, updateFilterControls])

    useEffect(()=>{
        if(chartData.length){
            renderChart();
        }
    }, [chartData, renderChart])

    //** For Render Chart End */

    return (
        <div className='previewHistoricalChartWrapper'>
            <div className="historicalDistributionChartPreview">
                <svg id="historicalDistributionChart" width='320' height="100"></svg>
            </div>
            <div className='previewStatsWrapper'>
                <div className='previewStatsBlk'>
                    <span>Valid from: </span>
                    <strong>{mnDate(validDates?.start).format(dateFormat.default)}</strong>
                </div>
                <div className='previewStatsBlk'>
                    <span>Valid to: </span>
                    <strong>{mnDate(validDates?.end).format(dateFormat.default)}</strong>
                </div>
                <div className='previewStatsBlk'>
                    <span>Total jurisdiction: </span>
                    <strong>{jurisdictionData?.length ?? 0}</strong>
                </div>
                <div className='previewStatsBlk'>
                    <span>Total records: </span>
                    <strong>{totalRecordCount}</strong>
                </div>
            </div>
        </div>
    );
};

export default HistoricalDistributionChart;
