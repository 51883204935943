import { useProjectPreviewStore } from 'modules/organization/store';
import React from 'react';

const PreviewLegend: React.FC = () => {
  const { getLegendValues } = useProjectPreviewStore();

  const legendValues = getLegendValues();
  return (
    <ul className='previewLegendsWrapper'>
      {legendValues.map(({ count, displayText, fillColor }) => (
        <li key={displayText}>
            <span className='legendColor' style={{ backgroundColor: fillColor }}></span>
            <span>(<strong>{count}</strong>)</span>
            <span>{displayText}</span>
        </li>
      ))}
    </ul>
  );
};

export default PreviewLegend;
