import { IApiBaseResponse } from "models/interface";
import { RoutesEnum } from "../../../models/enums";
import { ApiService } from "../../shared/services";
import { URLService } from "./url.service";
import { ICitationWithText, ILaw, IResponsePreviewData } from "../models/interface";

export class ProjectPublishService {

  public async getPreviewData(project_slug: string) {
      const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_GET_PREVIEW_DATA)
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponsePreviewData>>(updatedURL)
  }

  public async getLawData(lawIds: number[]) {
      return (await new ApiService().privateClient()).get<IApiBaseResponse<{lawList: ILaw[]}>>(RoutesEnum.ORG_GET_LAW_DATA,{ params: { law_ids: lawIds }})
  }

  public async getCitationData(citationIds: number[]) {
      return (await new ApiService().privateClient()).get<IApiBaseResponse<{citationList: ICitationWithText[]}>>(RoutesEnum.ORG_GET_CITATION_DATA,{ params: { citation_ids: citationIds }})
  }
}
