import { FC, Fragment, useCallback, useState } from "react";
import { useProjectPreviewStore } from "modules/organization/store";
import { Button, Input, Table, Typography } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { PreviewQuestionAttachedCautionNote, PreviewQuestionAttachedCitations, getPreviewAnswer } from "../shared/PreviewQuestionAnswerList";
import { CloseCircleFilled } from "@ant-design/icons";

const PreviewTableView:FC<{setCurrentMode: (mode: 'profile')=> void}> = ({setCurrentMode}) => {
    const { filteredData, profileViewFilter, flatQuestionTree, filterControls, getCurrentAnswers, setProfileViewFilter } = useProjectPreviewStore();
    const [inputSearchText, setInputSearchText] = useState('');

    const getTableHeader = useCallback(() => {
        let questionIds:number[] = [];
        if(filterControls.filter.exploreQuestionData){
            questionIds.push(filterControls.filter.exploreQuestionData.question_id);
        }
        else if(filterControls.filter.filterQuestionData){
            questionIds = [...filterControls.filter.filterQuestionData.map(each => each.question_id)];
        }

        const filterFlatQuestions = flatQuestionTree.filter(each => questionIds.includes(each.key));
        let columns:ColumnsType<any> = filterFlatQuestions.map(eachQuestion => {
            return  {
                title: 'Q'+eachQuestion.layer_string+ ' ' +eachQuestion.title,
                dataIndex: 'question_'+eachQuestion.key,
                key: eachQuestion.key.toString(),
                width: 200
            }
        });

        columns = [
            {
                title: 'Jurisdiction', 
                dataIndex: 'jurisdiction', 
                key: 'Jurisdiction', 
                fixed: 'left',
                sorter: {
                    compare: (a:any, b:any) => a.jurisdictionName.localeCompare(b.jurisdictionName)
                },
                width: 150,
            }
            , ...columns
        ]

        let allAnswerData:any[] = [];

        filteredData?.forEach(eachFilteredJurisdiction =>{
            const currentSelectedRecord = eachFilteredJurisdiction.current_selected_record;
            const jurisdictionId = eachFilteredJurisdiction.jurisdiction_id;
            const answers = getCurrentAnswers(jurisdictionId, currentSelectedRecord);
            
            const answerData:any = {
                jurisdiction: <Typography.Link onClick={()=>{
                    setProfileViewFilter({
                        ...profileViewFilter,
                        selected_jurisdiction: [jurisdictionId]
                      });
                    setCurrentMode('profile');
                }}>{eachFilteredJurisdiction.name}</Typography.Link>,
                jurisdictionName: eachFilteredJurisdiction.name
            };

            let searchText = eachFilteredJurisdiction.name;

            filterFlatQuestions.forEach(eachQuestion => {
                const findAnswerOfQuestion = answers.find(each => each.question_id === eachQuestion.key);

                answerData['question_'+eachQuestion.key] = '';
                answerData['question_'+eachQuestion.key+'_text'] = '';
                answerData['question_'+eachQuestion.key+'_text_csv'] = '';
                if(findAnswerOfQuestion){

                    const answerText = getPreviewAnswer(eachQuestion,  findAnswerOfQuestion).join(', ');

                    const cautionNoteTextForCsv = findAnswerOfQuestion.caution_note ? '(Caution: ' + findAnswerOfQuestion.caution_note + ')' : '';
                    const citationsTextArrForCsv = findAnswerOfQuestion.citations?.map(each => '('+each.pin_cite+')');
                    const citationsTextForCsv = citationsTextArrForCsv ? '('+ citationsTextArrForCsv.join(' ') + ')' : '';
                    
                    answerData['question_'+eachQuestion.key] = <div className="previewAnswerBlk">
                        {findAnswerOfQuestion.citations && findAnswerOfQuestion.citations.length &&
                            <PreviewQuestionAttachedCitations citations={findAnswerOfQuestion.citations}/>
                        }
                        {findAnswerOfQuestion.caution_note &&
                            <PreviewQuestionAttachedCautionNote cautionNote={findAnswerOfQuestion.caution_note} />
                        }
                        <span>{answerText}</span>
                    </div>;
                    answerData['question_'+eachQuestion.key+'_text'] = answerText;
                    answerData['question_'+eachQuestion.key+'_text_csv'] = cautionNoteTextForCsv + ' ' + answerText + ' ' + citationsTextForCsv;
                    searchText = searchText+ ' '+ answerText;
                }
                
            });
            answerData.searchText = searchText.toLocaleLowerCase();
            allAnswerData.push(answerData);
        });

        if(inputSearchText !== ''){
            allAnswerData = allAnswerData.filter(each => each.searchText.includes(inputSearchText))
        }

        return { columns, allAnswerData };

    }, [filterControls, flatQuestionTree, filteredData, profileViewFilter, inputSearchText, getCurrentAnswers, setCurrentMode, setProfileViewFilter]);

    const downloadTable = () =>{
        const tableData = getTableHeader();
        const csvData:Array<string[]> = [];

        csvData.push(tableData.columns.map(each => each.title as string));

        tableData.allAnswerData.forEach(each => {
            const eachRow = [each.jurisdictionName];
            tableData.columns.forEach(eachCol => {
                if(each['question_'+eachCol.key+'_text_csv'])
                eachRow.push(each['question_'+eachCol.key+'_text_csv'])
            });
            csvData.push(eachRow)
        });

        const csvContentw = csvData.map(row => row.map(value => typeof value === 'string' && value.includes(',') ? `"${value.trim()}"` : value.trim()).join(',')).join('\n');


        const blob = new Blob([csvContentw], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', objUrl)
        link.setAttribute('download', 'table_view_report.csv')
        link.textContent = 'Click to Download';
        link.click(); 

    }

    const tableHeader = getTableHeader();

    return (
        <div className="tabContent">
            {(filterControls.filter.exploreQuestionData || filterControls.filter.filterQuestionData) &&
                <Fragment>
                    <div className="searchDownloadWrap">
                        <div className="searchBlk">
                            <Input.Search
                                aria-label={"Search"}
                                title={"Search"}
                                placeholder="Search"
                                allowClear={{
                                    clearIcon: (
                                    <button className="clearBtn">
                                        <CloseCircleFilled />
                                    </button>
                                    ),
                                }}
                                onChange={(e)=>{
                                    setInputSearchText(e.target.value.toLocaleLowerCase());
                                }}
                                className="gap10"
                            />
                        </div>
                        <div className="downloadBtn">
                            <Button onClick={downloadTable} className="f" style={{}} type="primary">Download Table</Button>
                        </div>
                    </div>
                    <Table 
                        columns={tableHeader.columns}
                        dataSource={tableHeader.allAnswerData ??[]}
                        pagination={false}
                        showSorterTooltip={false}
                        size='small'
                        scroll={{x: 170 * tableHeader.columns.length}}
                    />
                </Fragment>
            }
            {!filterControls.filter.exploreQuestionData && !filterControls.filter.filterQuestionData &&
                <div className="alignCenter topGap2">No question selected.</div>
            }
            
        </div>
    )
}

export default PreviewTableView;