import {useState, useRef, FC, CSSProperties, Fragment} from "react";
import WorldMap from "./WorldMap";
import UsMap from "./UsMap";
import ReactDOM from "react-dom";
import OutsideClickWrapper from "modules/shared/components/OutsideClickWrapper";
import * as d3 from "d3";
import { useProjectPreviewStore } from "modules/organization/store";
import { IFilteredJurisdictionData } from "modules/organization/models/interface";
import { Button } from "antd";
import {PreviewQuestionAnswerList} from "../shared/PreviewQuestionAnswerList";
import { dateFormat } from "config/commonConfig";
import "./map.css";
import PreviewLegend from "../shared/PreviewLegend";
import { mnDate } from 'modules/shared/services';

const PreviewMapView: FC<{setCurrentMode: (mode: 'profile')=> void}> = ({setCurrentMode}) => {
  const [currentHoveredJurisdictionId, setCurrentHoveredJurisdictionId] = useState<number | null>(null);
  const [currentClickedJurisdictionId, setCurrentClickedJurisdictionId] = useState<number | null>(null);
  const [actionTooltipStyle, setActionTooltipStyle] = useState<any>({});
  const [actionTooltipQuestionAreaStyle, setActionTooltipQuestionAreaStyle] = useState<any>({});

  const [hoveredCustomJurisdictionAreaName, setHoveredCustomJurisdictionAreaName] = useState<string | null>(null);
  const [clickedCustomJurisdictionAreaName, setClickedCustomJurisdictionAreaName] = useState<string | null>(null);

  const { filteredData, jurisdictionData, profileViewFilter, setProfileViewFilter } = useProjectPreviewStore();

  const outsideWrapperRef = useRef(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const getFilteredJurisdictionById = (jurisdictionId: number) => {
    const _filteredData:IFilteredJurisdictionData[] = JSON.parse(JSON.stringify(filteredData ?? []));

    return _filteredData.find(each => each.jurisdiction_id === jurisdictionId);
  }

  const showHoverTooltip = (event:any, jurisdictionId: number, customJurisdictionAreaName?: string) => {
    setCurrentHoveredJurisdictionId(jurisdictionId);
    setHoveredCustomJurisdictionAreaName(customJurisdictionAreaName ?? null);
    const tooltip = d3.select('#mapTooltipHover');
    const { innerWidth } = window;
    const left = event.pageX;
    const top = event.pageY;
    
    const mapTooltipHover = document.getElementById("mapTooltipHover");

      
    const scrollWidth = mapTooltipHover?.scrollWidth ?? 100;
    let leftPosition = left - scrollWidth / 2;
    const isRight = innerWidth <  left + scrollWidth ? false : true;

    if(!isRight){
      leftPosition = leftPosition - scrollWidth / 2;
    }

    tooltip
    .style('left', `${leftPosition}px`)
    .style('top', `${top - 10}px`)
    .style('transform', `translateY(-100%)`)
    .style('display', 'block');
  }
  const hideHoverTooltip = () => {
    const tooltip = d3.select('#mapTooltipHover');
    tooltip.style('display', 'none');
  }

  const showActionTooltip = (event:any, jurisdictionId: number, customJurisdictionAreaName?: string) => {
    setTimeout(()=> {
      setCurrentClickedJurisdictionId(jurisdictionId);
      setClickedCustomJurisdictionAreaName(customJurisdictionAreaName ?? null);

      hideHoverTooltip();
      const { innerHeight, innerWidth } = window;
      const containerWidth = 500;
      const left = event.pageX;
      const top = event.pageY;

      const isTop = event.pageY > innerHeight-event.pageY ? true : false;
      const isRight = innerWidth <  left + containerWidth ? false : true;

      const tooltipStyle:CSSProperties = {
        position:'fixed',
        zIndex: 1000,
        width: containerWidth,
        left: isRight ? left - 15 : left,
        top: top 
      };

      const questionListAreaStyle:CSSProperties = {
        overflowY: 'auto'
      };

      if (isTop) {
        tooltipStyle.transform = 'translateY(-100%)';
        questionListAreaStyle.maxHeight = top - 200;
      }
      else{
        questionListAreaStyle.maxHeight = innerHeight - top - 200;
      }

      if(!isRight){
        if(tooltipStyle?.transform){
          tooltipStyle.transform = tooltipStyle?.transform + ' translateX(-100%)'
        }
        else{
          tooltipStyle.transform = 'translateX(-100%)'
        }
      }
      
      setActionTooltipStyle(tooltipStyle);
      setActionTooltipQuestionAreaStyle(questionListAreaStyle)
      
      const actionTooltip = d3.select('#mapTooltipClick');
      actionTooltip.style('display', 'block');
    }, 100)
   
  }

  const hideActionTooltip = () => {
    d3.select('#mapTooltipClick').style('display', 'none');
  }

  const renderHoveredTooltip = () => {
    if(currentHoveredJurisdictionId){
      const jurisdiction = getFilteredJurisdictionById(currentHoveredJurisdictionId);
      if(jurisdiction){
        const record = jurisdiction.records.find(each => each.record_id === jurisdiction.current_selected_record);
        return (
          <Fragment>
            <strong>
              {jurisdiction.name}
              {hoveredCustomJurisdictionAreaName && 
                <span> ({hoveredCustomJurisdictionAreaName})</span>
              }
            </strong>
            <div>{mnDate(record?.effective_from).format(dateFormat.default)} - {mnDate(record?.effective_from).format(dateFormat.default)}</div>
            <div>{record?.series_title}</div>
          </Fragment>
        )
      }
    }
  }

  const renderActionTooltip = () => {
    if(currentClickedJurisdictionId){
      const jurisdiction = getFilteredJurisdictionById(currentClickedJurisdictionId);
      if(jurisdiction){
        const record = jurisdiction.records.find(each => each.record_id === jurisdiction.current_selected_record);
        if(record){
          return (
            <Fragment>
              <div className="topBlk">
                <strong>
                  {jurisdiction.name}
                  {hoveredCustomJurisdictionAreaName && 
                    <span> ({clickedCustomJurisdictionAreaName})</span>
                  }
                </strong>
                <div>{mnDate(record?.effective_from).format(dateFormat.default)} - {mnDate(record?.effective_from).format(dateFormat.default)}</div>
                <div>{record?.series_title}</div>
              </div>
              <div style={actionTooltipQuestionAreaStyle}><PreviewQuestionAnswerList jurisdiction_id={jurisdiction.jurisdiction_id} current_selected_record={record?.record_id} show_caution_note={false} show_citations={false} /></div>
              <div className="btnWrap">
                <Button onClick={()=>{
                  hideActionTooltip()
                }}>Close</Button>
                <Button type="primary"
                onClick={()=>{
                  setProfileViewFilter({
                    ...profileViewFilter,
                    selected_jurisdiction: [jurisdiction.jurisdiction_id]
                  });
                  setCurrentMode('profile');
                }}
                >Go to profile view</Button>
              </div>
            </Fragment>
          )
        }

      }
    }
  }

  const whichMapRender = () => {
    jurisdictionData?.filter(each => !each.country || each.country?.toLowerCase() === 'united states' || each.country?.toLowerCase() === 'united states of america')
    const usaJurisdictionCount = jurisdictionData?.filter(each => each.country?.toLowerCase() === 'united states' || each.country?.toLowerCase() === 'united states of america');
    if(usaJurisdictionCount?.length === jurisdictionData?.length){
      return 'usa'
    }
    return 'world'
  }


  return (
    <div
      className="tabContent"
      id="tabpanel-map"
      role="tabpanel"
      aria-labelledby="tab-map"
    >
      <div className="previewMapArea" ref={mapContainerRef}>
        {jurisdictionData && mapContainerRef &&
          <Fragment>
            {whichMapRender() === 'world' &&
              <WorldMap showHoverTooltip={showHoverTooltip} hideHoverTooltip={hideHoverTooltip} showActionTooltip={showActionTooltip} containerRef={mapContainerRef}/>
            }
            {whichMapRender() === 'usa' &&
              <UsMap showHoverTooltip={showHoverTooltip} hideHoverTooltip={hideHoverTooltip} showActionTooltip={showActionTooltip}  containerRef={mapContainerRef}/>
            }
            <PreviewLegend />
          </Fragment>
        }
      </div>
      {ReactDOM.createPortal(
        <OutsideClickWrapper ref={outsideWrapperRef} onOutsideClick={() => {
          hideActionTooltip();
        }}>
          <div id="mapTooltipHover" className="previewMapTooltip">
            {currentHoveredJurisdictionId &&
              
              <div>
                {renderHoveredTooltip()}
              </div>
            
            }
          </div>
          <div id="mapTooltipClick" className="previewMapTooltip" style={actionTooltipStyle}>
            <Button
              className="closeBtn"
              onClick={() => {
                hideActionTooltip()
              }}
            >
              X
            </Button>
            {renderActionTooltip()}
          </div>
        </OutsideClickWrapper>,
        document.body
      )}
    </div>
  );
};

export default PreviewMapView;
