import { useState, useLayoutEffect } from "react";
import AppRoute from "./routes/router";
import ErrorBoundary from "modules/shared/components/ErrorBoundary";
import { ConfigProvider, Result } from "antd";
import {
  DisconnectOutlined
} from '@ant-design/icons';

import "../src/assets/css/appStyle.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/ant-custom.css";
import "../src/assets/css/dev-temp-custom.css";


function App() {
  const [online, setOnline] = useState(true);

  useLayoutEffect(() => {

    function handleEvents(value: boolean) {
      setOnline(value)
    }

    window.addEventListener('online', () => handleEvents(true));
    window.addEventListener('offline', () => handleEvents(false));

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('online', () => handleEvents(true));
      window.removeEventListener('offline', () => handleEvents(false));
    };
  }, [online]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#A41E35",
          colorLink: '#A41E35',
          colorLinkActive: '#3c3c3c',
          colorLinkHover: '#3c3c3c',
          colorError: "#A41E35",
          colorBorder: "#949494",
          borderRadius: 4,
          fontFamily: "'Roboto', sans-serif;",
        },
      }}
    >
      {!online && (
        <div className="offline-state">
          <Result
            status="error"
            icon={<DisconnectOutlined />}
            title={<h1 aria-live="polite">You have no Internet</h1>}
            subTitle="The network connection is lost. Please wait for sometime."
          />
        </div>
      )}
      <ErrorBoundary>
        <AppRoute />
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
