import { Button, Result } from 'antd';
import React from 'react';
import { usePageTitle } from '../hooks';
import { ExceptionStatusType } from 'antd/es/result';
import HttpStatusCode from 'models/enums/httpStatusCode.enums';

interface Props {
  statusCode?: HttpStatusCode;
}

const contents: {[key: number]: {
  title: string;
  subTitle: string;
}} = {
  404: {
    title: "Page not found",
    subTitle: "Sorry, the page you visited does not exist."
  },
  500: {
    title: "Something went wrong",
    subTitle: "Sorry, there is a system error."
  },
  403: {
    title: "Access denied",
    subTitle: "You do not have the permission to access this page."
  },
  401: {
    title: "Unauthorized",
    subTitle: "You do not authorized to access this page."
  }
} as const;

const NotFound: React.FC<Props> = ({statusCode = 404}) => {
  usePageTitle("MonQcle - Page not found");
  return (
    <div className="verifyMsgWrap">
      <Result
        status={[404, 500, 403, 401].includes(statusCode) ? statusCode as ExceptionStatusType : 500}
        title={contents[[404, 500, 403, 401].includes(statusCode) ? statusCode : 500].title}
        subTitle={contents[[404, 500, 403, 401].includes(statusCode) ? statusCode : 500].subTitle}
      />
    </div>
  );
};

export default NotFound;
