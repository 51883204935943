import { useEffect, useCallback, FC } from "react";
import * as d3 from "d3";
import { useProjectPreviewStore } from "modules/organization/store";
import worldMapData from "./mapData/world_map.json";
import { IFilteredJurisdictionData, IJurisdictionData } from "modules/organization/models/interface";
import { countryList } from "./mapData/countryList";
import { ZoomInOutlined, ZoomOutOutlined, AimOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";

let projection:any = null;

const WorldMap:FC<{
    containerRef: React.RefObject<HTMLDivElement>;
    showHoverTooltip: (event: any, jurisdictionId: number, customJurisdictionAreaName?: string) => void;
    hideHoverTooltip: () => void;
    showActionTooltip: (event: any, jurisdictionId: number, customJurisdictionAreaName?: string) => void;
}> = ({containerRef, showHoverTooltip, hideHoverTooltip, showActionTooltip}) => {

    const { jurisdictionData, filteredData } = useProjectPreviewStore();


    const renderMap = useCallback(()=> {
        const _worldMapData = [...worldMapData.features];
        const _jurisdictionData :IJurisdictionData[] =  JSON.parse(JSON.stringify(jurisdictionData?? []));
        const _countryJurisdictions = _jurisdictionData.filter(each=> each.jurisdiction_type_id === 1);
        const _usStateJurisdictions = _jurisdictionData.filter(each=> each.jurisdiction_type_id === 2);

        _worldMapData.forEach(eachMapData => {
            if(eachMapData.id && eachMapData.id !== ''){
                /** For Country */
                const findCountry = countryList.find(each => each.code3 === eachMapData.id);
                if(findCountry){
                    const _findCountryJurisdiction = _countryJurisdictions.find(each => {
                        return (
                            each.country_code?.toLowerCase() === findCountry.code2.toLowerCase() 
                            || each.country_code?.toLowerCase() === findCountry.code3.toLowerCase()
                            || each.name?.toLowerCase() === findCountry.name.toLowerCase()
                        )
                    });

                    if(_findCountryJurisdiction){
                        eachMapData.properties.jurisdiction_id = _findCountryJurisdiction.jurisdiction_id.toString();
                    }
                }
            }
            else if(eachMapData.properties.stusab && eachMapData.properties.stusab !== ''){
                /** For US State */
                const _find_usStateJurisdiction = _usStateJurisdictions.find(each => {
                    return (
                        eachMapData?.properties?.stusab?.toLowerCase() === each?.state_code?.toLowerCase()
                        || eachMapData?.properties?.name.toLowerCase() === each.name.toLowerCase()
                    )
                });

                if(_find_usStateJurisdiction){
                    eachMapData.properties.jurisdiction_id = _find_usStateJurisdiction.jurisdiction_id.toString();
                }

            }
        });

        // Start Map Render
        const previewWorldMap = document.getElementById('mapArea');
        if(previewWorldMap){
            previewWorldMap.innerHTML = ''
        }
        const width = containerRef?.current?.offsetWidth ?? 750 - 1;
        const height = width / 1.5;

        projection = d3.geoMercator()
        .translate([width / 2, height / 2])
        .scale((width - 1) / (2 * Math.PI))
        .center([0, 10]);

        const svg = d3.select("#previewWorldMap #mapArea")
        .append("svg")
        .attr("width", width)
        .attr("height", height);

        const path = d3.geoPath()
            .projection(projection);

        const g = svg.append("g");
        g.selectAll("path")
        .data(worldMapData.features)
        .enter()
        .append("path")
        .attr('stroke-width', 0.5)
        .attr('class', 'mapPath')
        .attr("d", path as any);


        const zoom = d3.zoom()
        .scaleExtent([1, 8])
        .on("zoom", function (event) {
            g.attr("transform", event.transform);

            g.selectAll("path")
            .attr("d", path.projection(projection) as any);

            g.selectAll("path")
            .attr('stroke-width', 0.5 / event.transform.k);

            svg.selectAll("circle.circleJurisdiction")
            .attr("transform", event.transform)
            .attr("r", 4 / event.transform.k)
            .attr('stroke-width', 0.6 / event.transform.k);
            
            svg.selectAll("circle.circleCustomJurisdiction")
            .attr("transform", event.transform)
            .attr("r", 3 / event.transform.k)
            .attr('stroke-width', 3 / event.transform.k);

        });

        svg.call(zoom as any).on("wheel.zoom", null);

        const zoomTo = (targetScale:number) => {
            svg.transition().duration(500)
            .call(zoom.scaleTo as any, targetScale);
        }

        d3.select('#zoom-in').on('click', function () {
            const transform = d3.zoomTransform(svg.node() as Element);
            const scale = transform.k;
            const targetScale = Math.min(8, scale * 1.2);
            zoomTo(targetScale);
        });

        d3.select('#zoom-out').on('click', function () {
            const transform = d3.zoomTransform(svg.node() as Element);
            const scale = transform.k;
            const targetScale = Math.max(1, scale / 1.2);
            zoomTo(targetScale);
        });

        d3.select('#reset').on('click', function () {
            zoomTo(1);
        });


    }, [jurisdictionData, containerRef]);


    useEffect(()=>{
        if(jurisdictionData){
            renderMap();
        }
    }, [jurisdictionData, filteredData, renderMap]);


    const fillAndPlotPointer = useCallback(()=> {
        const _filteredData:IFilteredJurisdictionData[] = JSON.parse(JSON.stringify(filteredData ?? []));

        const getFilteredJurisdictionById = (jurisdictionId: number) =>{
            return _filteredData.find(each => each.jurisdiction_id === jurisdictionId);
        }

        const getFillColor = (jurisdictionId: number) => {
            if(getFilteredJurisdictionById(jurisdictionId)){
                const jurisdiction = getFilteredJurisdictionById(jurisdictionId);
                const record = [...jurisdiction?.records ?? []].find(each => each.record_id === jurisdiction?.current_selected_record);
                if(jurisdiction && record){
                    filledJurisdictionByIds.push(jurisdiction.jurisdiction_id);
                    return record.fill_color ?? ''
                }
            }
            return  '';
        }
        
        const filledJurisdictionByIds:number[] = [];

        d3.selectAll('.mapPath')
        .style("fill", (d:any) => {
            if(d?.properties?.jurisdiction_id && d?.properties?.jurisdiction_id !== ''){
                return getFillColor(+d?.properties?.jurisdiction_id);
            }
            return "";
        })
        .on('mousemove', (event:any, d:any)=>{
            if(d?.properties?.jurisdiction_id && d?.properties?.jurisdiction_id !== ''){
                const jurisdiction = getFilteredJurisdictionById(+d?.properties?.jurisdiction_id);
                if(jurisdiction){
                    showHoverTooltip(event, jurisdiction.jurisdiction_id);
                }
            }
        })
        .on('mouseout', hideHoverTooltip)
        .on('click', (event:any, d:any)=>{
            if(d?.properties?.jurisdiction_id && d?.properties?.jurisdiction_id !== ''){
                const jurisdiction = getFilteredJurisdictionById(+d?.properties?.jurisdiction_id);
                if(jurisdiction){
                    showActionTooltip(event, jurisdiction.jurisdiction_id);
                }
            }
        })
        .style("cursor", (d:any) => {
            if(d?.properties?.jurisdiction_id && d?.properties?.jurisdiction_id !== ''){
                return 'pointer';
            }
            return "";
        });

        d3.selectAll('.circleJurisdiction').remove();
        const svg = d3.select("#previewWorldMap svg");



        // Plot Circle without custom jurisdiction
        svg.selectAll("circle.circleJurisdiction")
        .data(_filteredData.filter(each => !filledJurisdictionByIds.includes(each.jurisdiction_id) && each.jurisdiction_type_id !== 6))
        .enter()
        .append("circle")
        .attr("class","circleJurisdiction")
        .attr("cx", function (d) {
            const pro = projection([+d.lon, +d.lat]);
            if(pro){
                return pro[0];
            }
            return 0;
        })
        .attr("cy", function (d) {
            const pro = projection([+d.lon, +d.lat]);
            if(pro){
                return pro[1];
            }
            return 0;
        })
        .attr("r", 4)
        .attr('stroke', '#0D47A1')
        .attr('stroke-width', 0.6)
        .style("fill", (d) => {
            return getFillColor(d.jurisdiction_id);
        })
        .on('mousemove', (event:any, d:any)=>{
            if(d?.jurisdiction_id){
                const jurisdiction = getFilteredJurisdictionById(d.jurisdiction_id);
                if(jurisdiction){
                    showHoverTooltip(event, jurisdiction.jurisdiction_id);
                }
            }
        })
        .on('mouseout', hideHoverTooltip)
        .on('click', (event:any, d:any)=>{
            if(d.jurisdiction_id){
                const jurisdiction = getFilteredJurisdictionById(d.jurisdiction_id);
                if(jurisdiction){
                    showActionTooltip(event, jurisdiction.jurisdiction_id);
                }
            }
        })
        .style("cursor", "pointer");


        // Plot Circle only custom jurisdiction
        const customFilteredJurisdiction = _filteredData.filter(each => each.jurisdiction_type_id === 6 && each.custom_points);
        const customFilteredJurisdictionCircleData: {jurisdiction_id: number; lat: number; lon: number; point_name: string;}[] = [];

        if(customFilteredJurisdiction.length){
            customFilteredJurisdiction.forEach(each => {
                if(each.custom_points && each.custom_points.length){
                    each.custom_points.forEach(eachCustomPoints => {
                        customFilteredJurisdictionCircleData.push({
                            jurisdiction_id: each.jurisdiction_id,
                            lat: eachCustomPoints.lat,
                            lon: eachCustomPoints.lon,
                            point_name: eachCustomPoints.name
                        });
                    })
                }
            })
        }

        if(customFilteredJurisdictionCircleData.length){
            svg.selectAll("circle.circleCustomJurisdiction")
            .data(customFilteredJurisdictionCircleData)
            .enter()
            .append("circle")
            .attr("class","circleCustomJurisdiction")
            .attr("cx", function (d) {
                const pro = projection([d.lon, d.lat]);
                if(pro){
                    return pro[0];
                }
                return 0;
            })
            .attr("cy", function (d) {
                const pro = projection([d.lon, d.lat]);
                if(pro){
                    return pro[1];
                }
                return 0;
            })
            .attr('stroke', (d) => {
                return getFillColor(d.jurisdiction_id);
            })
            .attr('stroke-width', 3)
            .attr("r", 3)
            .style("fill", "#FFFFFF")
            .on('mousemove', (event:any, d)=>{
                showHoverTooltip(event, d.jurisdiction_id, d.point_name);
            })
            .on('mouseout', hideHoverTooltip)
            .on('click', (event:any, d)=>{
                showActionTooltip(event, d.jurisdiction_id, d.point_name);
            })
            .style("cursor", "pointer");
            
        }

    }, [filteredData ]);


    useEffect(()=>{
        if(filteredData){
            fillAndPlotPointer();
        }
    }, [filteredData, fillAndPlotPointer])

    return (
        <div>
            <div id="previewWorldMap">
                <div id="mapArea"></div>
                <div className="mapBtnWrap" role="group" aria-label="..." id="float-button-group">
                    <Tooltip title="Zoom In" trigger={['hover','focus']}><Button className="btn btn-default" id="zoom-in"><ZoomInOutlined /></Button></Tooltip>
                    <Tooltip title="Zoom Out" trigger={['hover','focus']}><Button className="btn btn-default" id="zoom-out"><ZoomOutOutlined /></Button></Tooltip>
                    <Tooltip title="Reset" trigger={['hover','focus']}><Button className="btn btn-default" id="reset" onClick={()=> {
                        renderMap();
                        setTimeout(()=>{
                            fillAndPlotPointer()
                        }, 200)
                    }}><AimOutlined /></Button></Tooltip>
                </div>
            </div>
        </div>
    )
}

export default WorldMap;