import { useState } from "react";
import HistoricalDistributionChart from "./components/HistoricalDistributionChart";
import PreviewFilterContainer from "./components/PreviewFilterContainer";

const OrgProjectPreviewFilter = () => {
  const [showFilterForm, setShowFilterForm] = useState(false);
  return (
    <div className="previewLeftContent">
      <HistoricalDistributionChart />
      <span className={showFilterForm ? 'previewFilterControl active': 'previewFilterControl'} onClick={()=> setShowFilterForm(!showFilterForm)}>Show Filter</span>
      <PreviewFilterContainer showFilterForm={showFilterForm}/>
    </div>
  )
};

export default OrgProjectPreviewFilter;
