export class TokenService {
    private static instance: TokenService;
    private _token = ''
  
    private constructor() {}
  
    public static getInstance(): TokenService {
      if (!TokenService.instance) {
        TokenService.instance = new TokenService();
      }
      return TokenService.instance;
    }

    public setToken(token: string){
        this._token = token;
    }

    public getToken(){
        return this._token;
    }

}